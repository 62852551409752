import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Switch } from '@mantine/core';
import { Button, ButtonGroup, Col, Row, Modal, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import 'react-datetime/css/react-datetime.css';
import { languageMap, LDEs, LdeMap } from '../payer/Data';
import MandatoryText from '../../../components/MandatoryText';
import CustomTextInput from '../../../components/core/InputFields/CustomTextInput/CustomTextInput';
import RegionSelect from '../../../components/core/InputFields/RegionSelect/RegionSelect';
import TransZoneSelect from '../../../components/core/InputFields/TransportationZoneSelect/CustomTransZoneSelect';
import LangSelect from '../../../components/core/InputFields/LanguageSelect/LanguageSelect';
import CustomTelInput from '../../../components/core/InputFields/CustomTelInput/CustomTelInput';
import {
  FetchShipToBapi,
  FetchRegions,
  FetchSegments,
  FetchSubSegments,
  FetchDeliveringPlants,
  FetchShippingConditions,
  FetchPOCSuppliers,
  FetchProfitCenters,
  FetchTransportationZones,
  FetchLanguages,
  FetchTrailerTypes,
  FetchTruckTypes,
} from './FlowCallAPI';
import { telephoneOptions, subChannels, exciseOptions } from '../../../utils/Utils';
import { formTemplate } from '../Data';
import { useFormFields } from './HooksLib';
import { validateFields } from './FormValidation';

import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
  postalCodeLength,
  numberRange,
  shipToIDRegex,
} from '../../../utils/Utils';

import Sidebar from '../../../components/layout/Sidebar/Sidebar';

const CreateCustShipTo = (props) => {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) navigate('/');
  }, [isAuthenticated]);

  const { instance, accounts } = useMsal();

  const [isDisable, setIsDisable] = useState(false);
  const [dateRange, setDateRange] = useState(new Date());
  const [linkForward, setLinkForward] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [isDisableFields, setIsDisableFields] = useState(false);
  const [fetchSubSegments, setFetchSubSegments] = useState(false);
  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const mainPageData = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
  const country = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).country || '';
  const companyCode =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).companyCode || '';
  const salesOrg = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).salesOrg || '';
  const customerReqType =
    JSON.parse(sessionStorage.getItem('createCustomerMainPage')).customerReqType || '';
  const shipToType = JSON.parse(sessionStorage.getItem('createCustomerMainPage')).shipToType || '';
  const regionsRes = FetchRegions(country, isDisableFields);
  const segmentsRes = FetchSegments(country, shipToType, isDisableFields);
  const subSegmentsRes = FetchSubSegments(
    country,
    shipToType,
    inputs.segment,
    isDisableFields,
    fetchSubSegments,
  );
  const deliveringPlantsRes = FetchDeliveringPlants(country, isDisableFields);
  const shippingConditionsRes = FetchShippingConditions(country, isDisableFields);
  const pocSuppliersRes = FetchPOCSuppliers(country, shipToType, isDisableFields);
  const profitCentersRes = FetchProfitCenters(country, isDisableFields);
  const transportationZonesRes = FetchTransportationZones(country, isDisableFields);
  const shipToDataRes = FetchShipToBapi(inputs.existingShipToID, companyCode, salesOrg, isCopying);
  const languagesRes = FetchLanguages(country, isDisableFields);
  const trailerTypesRes = FetchTrailerTypes(country, isDisableFields);
  const truckTypesRes = FetchTruckTypes(country, isDisableFields);
  const transportationOptions = transportationZonesRes.transportationZones.map((val) => {
    return {
      ...val,
      transZCode: val.code,
      transZText: val.transportationZone,
      // erpTranspZone: val.erpTranspZone,
      transZDesc: `${val.code} - ${val.transportationZone}`,
    };
  });
  const truckOptions = truckTypesRes.trucktypes.map((val) => {
    return {
      ...val,
      truckCode: val.truckCode,
      truckText: val.truckDesc,
      truckDesc: `${val.truckCode} - ${val.truckDesc}`,
    };
  });
  const trailerOptions = trailerTypesRes.trailertypes.map((val) => {
    return {
      ...val,
      trailerCode: val.trailerCode,
      trailerText: val.trailerDesc,
      trailerDesc: `${val.trailerCode} - ${val.trailerDesc}`,
    };
  });
  const languageOptions = languagesRes.languages.map((val) => {
    return {
      ...val,
      langCode: val.langCode,
      langText: val.langText,
      langDesc: `${val.langCode} - ${val.langText}`,
    };
  });
  useEffect(() => {
    if (
      (country == 'Netherlands' && !shipToType.includes('National')) ||
      (country == 'Belgium' &&
        !(shipToType.startsWith('National') || shipToType == 'Off-Trade – Direct'))
    ) {
      inputs.ediOption = 'No';
      inputs.glnIlnCode = '';
      inputs.ediEmail = '';
      inputs.ediType = '';
      // setInputs(inputs);
    }
  }, [shipToType]);
  useEffect(() => {
    if (
      props.draftTicketNum[0] !== 'D' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected' &&
      props.status !== 'Duplicate'
    ) {
      if (country == 'Germany') {
        inputs.ldeOption = 'No';
      }
      setIsDisable(true);
    }

    if (['Netherlands', 'United Kingdom', 'France', 'Germany'].includes(country)) {
      if (inputs.ediOption == '') inputs.ediOption = 'No';
    }
    if (
      (country == 'Netherlands' && !shipToType.includes('National')) ||
      (country == 'Belgium' &&
        !(shipToType.startsWith('National') || shipToType == 'Off-Trade – Direct'))
    ) {
      inputs.ediOption = 'No';
      inputs.glnIlnCode = '';
      inputs.ediEmail = '';
      inputs.ediType = '';
      // setInputs(inputs);
    }
    if (['Netherlands', 'Belgium'].includes(country)) {
      if (
        shipToType.startsWith('On-Trade') ||
        shipToType.startsWith('Off-Trade') ||
        shipToType === 'Miscellaneous'
      ) {
        inputs.subChannel = 'XXX - Not Applicable';
      } else if (shipToType.startsWith('National')) inputs.subChannel = '001 - National Customer';
    } else if (country == 'France') {
      if (!shipToType.startsWith('Wholesale') && shipToType != 'Off-Trade – Direct') {
        inputs.subChannel = 'XXX - Not Applicable';
      } else inputs.subChannel = '008 - Wholesalers';
    }

    switch (country) {
      case 'Netherlands':
        if (['Only Ship To', 'Prospect'].includes(customerReqType)) {
          inputs.beesRelevantFlag = false;
        }
        if (
          ['Prospect'].includes(customerReqType) &&
          ['National On-Trade Direct', 'On-Trade – Direct'].includes(shipToType)
        ) {
          inputs.beesRelevantFlag = true;
        }
        if (
          ['Full Creation', 'New Owner'].includes(customerReqType) &&
          ['On-Trade – Direct', 'National On-Trade Direct'].includes(shipToType)
        ) {
          inputs.beesRelevantFlag = true;
        }
        if (inputs.language == '') inputs.language = 'Dutch';
        if (inputs.shippingCondition == '') {
          if (shipToType.includes('Indirect')) inputs.shippingCondition = 'T1';
          else inputs.shippingCondition = 'TJ';
        }
        if (shipToType.startsWith('Off-Trade')) {
          console.log('truck and trailer check');
          inputs.truckCode = 'ZNLTR3';
          inputs.truckDesc = 'Tractor 3 assen NL';
          inputs.truckType = 'ZNLTR3';
          inputs.trailerCode = 'ZNLSR';
          inputs.trailerDesc = 'NL-Semi Trailer';
          inputs.trailerType = 'ZNLSR';
        }
        break;

      case 'United Kingdom':
        if (inputs.language == '') inputs.language = 'English';
        break;

      case 'France':
        if (inputs.language == '') inputs.language = 'French';

        if (shipToType == 'On-Trade – Direct') inputs.deliveringPlant = 'FR06';
        if (shipToType.includes('On-Trade') || shipToType.includes('Wholesale')) {
          if (inputs.profitCenter == '') inputs.profitCenter = '1200FR00';
        }
        if (shipToType == 'Wholesale– Direct') {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
          inputs.subSegment = '16';
          inputs.subSegmentDesc = 'Wholesaler';
        } else if (shipToType == 'Wholesale– Indirect') {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
          inputs.subSegment = 'BG';
          inputs.subSegmentDesc = 'Sub Agent';
        }
        break;

      case 'Belgium':
        if (['On-Trade – Direct', 'National On-Trade Direct'].includes(shipToType)) {
          inputs.beesRelevantFlag = true;
        }
        inputs.promoNews = true;
        if (customerReqType == 'Ship To') inputs.exciseDutyType = '002';
        if (inputs.language == '') inputs.language = 'Dutch';
        if (inputs.transportationZone == '') inputs.transportationZone = 'BE15';
        if (shipToType.startsWith('Wholesaler')) {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
        }
        break;

      case 'Germany':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T2';
        if (shipToType.includes('Wholesale')) {
          inputs.segment = '16';
          inputs.segmentDesc = 'Wholesaler';
          inputs.subSegment = '16';
          inputs.subSegmentDesc = 'Wholesaler';
        }
        break;

      case 'Austria':
        if (inputs.language == '') inputs.language = 'German';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Denmark':
        inputs.shippingCondition = 'X1';
        if (inputs.language == '') inputs.language = 'Danish';
        break;

      case 'Finland':
        if (inputs.language == '') inputs.language = 'Finnish';
        inputs.shippingCondition = 'X1';
        if (inputs.transportationZone == '') inputs.transportationZone = '0000000001';
        break;

      case 'Italy':
        if (inputs.language == '') inputs.language = 'Italian';
        break;

      case 'Luxembourg':
        if (inputs.language == '') inputs.language = 'French';
        break;

      case 'Norway':
        if (inputs.language == '') inputs.language = 'Danish';
        inputs.deliveringPlant = 'NO02';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        if (inputs.transportationZone == '') inputs.transportationZone = '0000000001';
        break;

      case 'Poland':
        if (inputs.language == '') inputs.language = 'Polish';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Sweden':
        if (inputs.language == '') inputs.language = 'Swedish';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      case 'Switzerland':
        if (inputs.language == '') inputs.language = 'French';
        if (inputs.shippingCondition == '') inputs.shippingCondition = 'T1';
        break;

      default:
        break;
    }
    if (inputs.exciseDutyType == '') {
      // if (country == 'Belgium' && shipToType == 'Off-Trade – Direct') {
      //   inputs.exciseDutyType = '001';
      //   inputs.exciseDutyTypeDesc = '001-Excise Exempt Custom';
      // } else
      if (country == 'United Kingdom') {
        inputs.exciseDutyType = '002-Excise Duty Customer';
        inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
      } else {
        inputs.exciseDutyType = '002';
        inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
      }
    }
  }, []);

  const handleBeesChange = (event) => {
    const { name, checked } = event.target;
    // Do something with the name and checked values
    console.log(`Switch ${name} is ${checked ? 'checked' : 'unchecked'}`);
    setInputs(name, checked);
  };
  const handlePromoChange = (event) => {
    const { name, checked } = event.target;
    // Do something with the name and checked values
    console.log(`Switch ${name} is ${checked ? 'checked' : 'unchecked'}`);
    setInputs(name, checked);
  };
  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();

    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, country, customerReqType, shipToType));
      // }
      setIsSubmitting(true);
      if (isDisable) props.setActivePage(e.target.getAttribute('data-link'));
    }
  };
  function AddTime(time, hours) {
    let res = '';
    if (inputs.delivWindow !== '') {
      let [h, m] = time.split(':');
      let date = new Date();
      date.setHours(h, m, 0);
      date.toString();

      res = `${date.getHours() + hours}:${date.getMinutes()}`;
      [h, m] = res.split(':');
      let date2 = new Date();
      date2.setHours(h, m, 0);
      setDateRange(date2);
    }
    console.log('function', res, dateRange);
    return res;
  }
  function getLang(id) {
    let obj = props.translations.find((o) => o.textID === id);

    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && isDisableFields) {
      setCopyClick(false);
      setIsCopying(true);
      console.log(inputs.existingShipToID);
      console.log('Yo');
      Object.entries(formTemplate.shipto).forEach(([key, value]) => {
        if (
          !['existingShipToID', 'language', 'subChannel', 'profitCenter', 'contactPerson'].includes(
            key,
          )
        )
          inputs[key] = value;
      });
    } else {
      setIsSubmitting(false);
      setIsCopying(false);
      setCopyClick(false);
    }
  }, [errors]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  const togglingSidebar = () => {
    setisSidebarOpen(!isSidebarOpen);
  };

  function copyShipToData(e) {
    if (!isDisable && shipToIDRegex.test(inputs.existingShipToID)) {
      setCopyClick(true);
      setIsCopying(true);
      console.log(`I am true ${isCopying}`);
      console.log(isCopying, inputs.existingShipToID, companyCode, salesOrg);
    }
  }

  useEffect(() => {
    if (Object.keys(shipToDataRes.shipToData).length !== 0 && isCopying) {
      Object.entries(shipToDataRes.shipToData).forEach(([key, value]) => {
        if (key === 'telephone' && value !== '') {
          inputs.generalTelephone = value;
          console.log('Done');
        }
        if (key === 'beesRelevantFlag' && value !== '') {
          inputs.beesRelevantFlag = true;
        }
        if (key === 'promoNews' && value !== '') {
          inputs.promoNews = true;
        }

        if (key in inputs) {
          if (key === 'dNodeCpt' && value !== '') {
            inputs.hierarchyTypeDNode = 'Existing';
            inputs[key] = value;
          } else if (key == 'segment' && value != '') {
            setFetchSubSegments(true);
            inputs[key] = value;
          } else if (key == 'exciseDutyType' && value != '') {
            if (value == '001') inputs.exciseDutyTypeDesc = '001-Excise Exempt Custom';
            else if (value == '002') inputs.exciseDutyTypeDesc = '002-Excise Duty Customer';
            inputs[key] = value;
          } else if (key == 'subChannel') {
            subChannels
              .filter((e) => e.key == value)
              .map((val) => {
                console.log('Found sub channel ', val);
                inputs.subChannel = val.value;
              });
          } else inputs[key] = value;
        }
      });
      sessionStorage.setItem('createCustomerShipTo', JSON.stringify(inputs));
    }
    setIsCopying(false);
    setFetchSubSegments(false);
  }, [shipToDataRes.shipToData]);

  useEffect(() => {
    if (inputs.delivWindow !== '') AddTime(inputs.delivWindow, 3);
    console.log('delivWindow changed');
  }, [inputs.delivWindow]);

  useEffect(() => {
    if (inputs.existingShipToID !== '') setIsDisableFields(true);
    else setIsDisableFields(false);
  }, [inputs.existingShipToID]);

  useEffect(() => {
    if (inputs.delivWindow !== '') AddTime(inputs.delivWindow, 3);
    console.log('delivWindow changed');
  }, [inputs.delivWindow]);

  const Input = (props) => <components.Input {...props} maxLength={8} />;
  console.log(typeof errors.delivWindow, 'delivery window error');
  const pocOptions = pocSuppliersRes.pocSuppliers.map((val) => {
    return {
      ...val,
      pocNumber: val.pocNumber,
      pocName: val.pocName,
      pocPayer: val.pocPayer,
      pocPriceListERP: val.pocPriceListERP,
      pocDesc: `${val.pocNumber} - ${val.pocName}`,
    };
  });

  const regionOptions = regionsRes.regions.map((val) => {
    return {
      ...val,
      regionCode: val.regionCode,
      regionText: val.regionText,
      erpTranspZone: val.erpTranspZone,
      regionDesc: `${val.regionCode} - ${val.regionText}`,
    };
  });

  const sidebarItems = [
    {
      title: getLang('Main Page'),
      ['data-link']: 'mainpage',
      ['data-back']: 'true',
      onClick: handleSubmit,
      active: false,
    },
    {
      title: getLang('Ship-To'),
      onClick: handleSubmit,
      active: true,
      ['data-link']: 'shipto',
      ['data-back']: true,
    },
    {
      title: getLang('Payer'),
      onClick: handleSubmit,
      ['data-link']: 'payer',

      active: false,
    },
    {
      title: getLang('Sold-To'),
      onClick: handleSubmit,
      ['data-link']: 'soldto',
      ['data-back']: 'true',
      active: false,
    },
    {
      title: getLang('Bill-To'),
      onClick: handleSubmit,
      ['data-link']: 'billto',
      ['data-back']: 'true',

      active: false,
    },
  ];

  return (
    isAuthenticated && (
      <form className="card" onSubmit={handleSubmit}>
        <Modal show={isCopying} centered>
          <Modal.Body>
            <Row>
              <div className="col-auto">
                <Spinner animation="border" variant="warning" />
              </div>
              <div className="text-left">
                <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="col-11 col-lg-12">
            <h3 className="card-title text-left">
              <strong style={{ color: 'rgb(227, 175, 50)' }}>
                {getLang('General Customer Creation')}
              </strong>{' '}
              - {'Ship To'}
            </h3>
          </div>
          <a
            className={`sidebar-toggler col-auto ${isSidebarOpen ? '' : 'collapsed'}`}
            // onClick={togglingSidebar}
          >
            <Sidebar sidebarItems={sidebarItems} />
            {/* <span className="sidebar-toggler-icon"></span> */}
          </a>
        </div>
        <Row className="sidebar-row">
          <div
            className={`col-12 col-lg-2 text-left sidebar ${
              isMobile ? (isSidebarOpen ? '' : 'collapse') : ''
            }`}
          >
            <Row>
              <ButtonGroup vertical className="btn-block">
                <Link
                  data-link="mainpage"
                  data-back="true"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {getLang('Main Page')}
                </Link>
                <Button className="btn-danger">{'Ship To'}</Button>
                {customerReqType !== 'Ship To' && (
                  <>
                    <Link data-link="payer" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Payer')}
                    </Link>
                    <Link data-link="soldto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Sold To')}
                    </Link>
                    <Link data-link="billto" className="btn btn-warning" onClick={handleSubmit}>
                      {getLang('Bill To')}
                    </Link>
                  </>
                )}
              </ButtonGroup>
            </Row>
          </div>
          <div className="col-12 col-lg-10">
            <div className="card-body">
              <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

              <div className="row">
                <div className="col-lg-12">
                  {!['Full Creation', 'Listing Fees', 'Ship To'].includes(customerReqType) && (
                    <>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Existing Ship-to ID')}
                              {['New Owner', '2nd Sold To'].includes(customerReqType) ? ' *' : ''}
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.existingShipToID ? 'is-invalid' : ''
                                }`}
                                name="existingShipToID"
                                maxLength="8"
                                placeholder={getLang('Enter Existing Ship-to ID')}
                                onChange={setInputs}
                                value={inputs.existingShipToID}
                                disabled={
                                  isDisable ||
                                  ['Full Creation', 'Listing Fees'].includes(customerReqType)
                                }
                              />
                              <span className="input-group-append">
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  style={{
                                    borderTopRightRadius: '3px',
                                    borderBottomRightRadius: '3px',
                                    marginTop: '-2px',
                                  }}
                                  onClick={copyShipToData}
                                  disabled={isDisable || inputs.existingShipToID === ''}
                                >
                                  {getLang('Copy')}
                                </button>
                              </span>
                              {errors.existingShipToID && (
                                <div className="invalid-feedback text-left">
                                  {getLang(errors.existingShipToID)}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}

                  <div className="row">
                    <div className="col-12 col-lg-4 text-left">
                      <h2
                        style={{
                          color: 'rgb(227, 175, 50)',
                        }}
                      >
                        {getLang('General Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <CustomTextInput
                      title="Name"
                      name={'name'}
                      placeholder={
                        ['Belgium', 'Germany'].includes(country)
                          ? getLang('POC Name')
                          : `Legal ${getLang('Name')}`
                      }
                      errors={errors}
                      inputs={inputs}
                      setInputs={setInputs}
                      //onChange={setInputs}
                      maxLength="35"
                      getLang={getLang}
                      mandatory={true}
                      disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                    />
                    <CustomTextInput
                      title="Name 2"
                      name={'name2'}
                      errors={errors}
                      inputs={inputs}
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength="35"
                      getLang={getLang}
                      disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                    />
                    <CustomTextInput
                      title="Postal Code"
                      name={'postalCode'}
                      errors={errors}
                      inputs={inputs}
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength={postalCodeLength(country)}
                      getLang={getLang}
                      mandatory={true}
                      disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                    />
                    {!(customerReqType === 'New Owner') && (
                      <CustomTextInput
                        title="Street"
                        name={'street'}
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        onChange={setInputs}
                        maxLength="35"
                        getLang={getLang}
                        mandatory={true}
                        disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                      />
                    )}
                    {!(customerReqType === 'New Owner') && (
                      <CustomTextInput
                        title="House Number"
                        name={'houseNumber'}
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        onChange={setInputs}
                        maxLength="10"
                        getLang={getLang}
                        mandatory={
                          country !== 'United Kingdom' && country !== 'France' ? true : false
                        }
                        disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                      />
                    )}
                    {!(customerReqType === 'New Owner') && (
                      <CustomTextInput
                        title="City"
                        name={'city'}
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        onChange={setInputs}
                        maxLength="35"
                        getLang={getLang}
                        mandatory={true}
                        disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                      />
                    )}
                    {!(customerReqType === 'New Owner') && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Country')}</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            placeholder=""
                            value={country}
                            readOnly
                          />
                        </div>
                      </div>
                    )}
                    {!(customerReqType === 'New Owner' || country == 'Luxembourg') && (
                      <RegionSelect
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        getLang={getLang}
                        regionOptions={regionOptions}
                        isDisable={isDisable}
                        isDisableFields={isDisableFields}
                      />
                    )}
                    {[
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                      'Italy',
                    ].includes(country) && (
                      <TransZoneSelect
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        getLang={getLang}
                        transportationOptions={transportationOptions}
                        isDisable={isDisable}
                        isDisableFields={isDisableFields}
                      />
                    )}
                    {!(customerReqType === 'New Owner') && (
                      <LangSelect
                        errors={errors}
                        inputs={inputs}
                        setInputs={setInputs}
                        getLang={getLang}
                        languageOptions={languageOptions}
                        isDisable={
                          isDisable || ['Luxembourg', 'France', 'Netherlands'].includes(country)
                        }
                        isDisableFields={isDisableFields || customerReqType == '2nd Sold To'}
                      />
                    )}

                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Telephone Number')}{' '}
                          {(['United Kingdom', 'Belgium', 'Finland', 'Italy'].includes(country) ||
                            (country === 'Netherlands' && customerReqType !== 'Prospect')) &&
                          !inputs.mobile
                            ? ' *'
                            : ''}
                        </label>

                        <input
                          type="tel"
                          className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                          name="generalTelephone"
                          placeholder={telephonePlaceholder(country)}
                          maxLength={telephoneLength(country)}
                          value={inputs.generalTelephone}
                          onChange={setInputs}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />

                        {errors.telephone && (
                          <div className="invalid-feedback text-left">{errors.telephone}</div>
                        )}
                      </div>
                    </div>

                    <CustomTelInput
                      title="Mobiles"
                      name={'mobile'}
                      errors={errors}
                      inputs={inputs}
                      placeholder={mobilePlaceholder(country)}
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength={mobileLength(country)}
                      getLang={getLang}
                      mandatory={
                        (['United Kingdom', 'Belgium', 'Finland', 'Italy'].includes(country) ||
                          (country === 'Netherlands' && customerReqType !== 'Prospect')) &&
                        !inputs.generalTelephone
                      }
                      disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                    />

                    <CustomTextInput
                      title="E-mail Address"
                      name={'email'}
                      errors={errors}
                      inputs={inputs}
                      placeholder="Eg.: abc@company.com"
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength="240"
                      getLang={getLang}
                      mandatory={true}
                      disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                    />
                    <CustomTextInput
                      title="Contact Person"
                      name={'contactPerson'}
                      errors={errors}
                      inputs={inputs}
                      placeholder=""
                      setInputs={setInputs}
                      onChange={setInputs}
                      maxLength="240"
                      getLang={getLang}
                      mandatory={
                        ['United Kingdom', 'Belgium', 'Italy', 'Luxembourg'].includes(country)
                          ? true
                          : false
                      }
                      disabled={isDisableFields || isDisable || customerReqType == '2nd Sold To'}
                    />

                    {[
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Italy',
                      'Luxembourg',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                      'United Kingdom',
                      'France',
                    ].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Profit Center')}{' '}
                            {[
                              'Austria',
                              'Denmark',
                              'Finland',
                              'Italy',
                              'Luxembourg',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                              'United Kingdom',
                              'Netherlands',
                            ].includes(country) ||
                            (country == 'France' && shipToType.includes('Off-Trade'))
                              ? ' *'
                              : ''}
                          </label>
                          {((country === 'France' && !shipToType.includes('Off-Trade')) ||
                            [
                              'Austria',
                              'Denmark',
                              'Finland',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                              'United Kingdom',
                              'Italy',
                              'Luxembourg',
                              'Belgium',
                            ].includes(country) ||
                            (country === 'Netherlands' && shipToType.includes('Off-Trade'))) && (
                            <input
                              type="text"
                              className={`form-control ${errors.profitCenter ? 'is-invalid' : ''}`}
                              name="profitCenter"
                              placeholder="Eg: 1234XX00"
                              maxLength="8"
                              value={inputs.profitCenter}
                              onChange={setInputs}
                              disabled={
                                isDisableFields ||
                                (country == 'France' && shipToType == 'Off-Trade - Indirect') ||
                                isDisable ||
                                customerReqType == '2nd Sold To'
                              }
                            />
                          )}
                          {country === 'France' && shipToType.includes('Off-Trade') && (
                            <Select
                              className="text-left"
                              name="profitCenter"
                              value={profitCentersRes.profitCenters.filter(
                                (opt) => opt.profitCenter == inputs.profitCenter,
                              )}
                              components={{ Input }}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cfcfcf',
                                  primary50: '#cfcfcf',
                                  primary: 'orange',
                                },
                              })}
                              isClearable
                              placeholder="Select"
                              isDisabled={
                                isDisableFields || isDisable || customerReqType == '2nd Sold To'
                              }
                              getOptionLabel={({ profitCenter }) => profitCenter}
                              getOptionValue={({ profitCenter }) => profitCenter}
                              options={profitCentersRes.profitCenters}
                              onChange={(e, action) => {
                                setInputs(e, action);
                              }}
                            />
                          )}
                          {errors.profitCenter && (
                            <div
                              className={`invalid-feedback${
                                country == 'France' ? '-file' : ''
                              } text-left`}
                            >
                              {errors.profitCenter}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {customerReqType !== 'New Owner' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('EDI')}
                            {['France'].includes(country) &&
                            ['Off-Trade – Direct', 'Off-Trade - Indirect'].includes(shipToType)
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            className={`form-control ${errors.ediOption ? 'is-invalid' : ''}`}
                            name="ediOption"
                            value={inputs.ediOption}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              (country == 'Netherlands' && !shipToType.includes('National')) ||
                              (country == 'Belgium' && !shipToType.startsWith('National')) ||
                              isDisable ||
                              customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {errors.ediOption && (
                            <div className="invalid-feedback text-left">
                              {getLang(errors.ediOption)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {inputs.ediOption === 'Yes' && (
                      <>
                        <CustomTextInput
                          title="GLN/ILN CODE"
                          name={'glnIlnCode'}
                          errors={errors}
                          inputs={inputs}
                          placeholder="Eg: 1234567890123"
                          setInputs={setInputs}
                          onChange={setInputs}
                          maxLength="13"
                          getLang={getLang}
                          mandatory={true}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                        <CustomTextInput
                          title="EDI Email Address"
                          name={'ediEmail'}
                          errors={errors}
                          inputs={inputs}
                          placeholder="Eg.: abc@company.com"
                          setInputs={setInputs}
                          onChange={setInputs}
                          maxLength="240"
                          getLang={getLang}
                          mandatory={true}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                      </>
                    )}
                    {customerReqType === 'Ship To' && country == 'Germany' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('LDE')}</label>
                          <select
                            name="ldeOption"
                            className="form-control"
                            onChange={setInputs}
                            value={inputs.ldeOption}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {inputs.ldeOption == 'Yes' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('LDE Option')}
                            {inputs.ldeOption == 'Yes' ? ' *' : ''}
                          </label>
                          <select
                            name="ldeType"
                            className={`form-control${errors.ldeType ? ' is-invalid' : ''}`}
                            onChange={setInputs}
                            value={inputs.ldeType}
                            disabled={isDisable}
                          >
                            <option value="">Select</option>
                            {Object.entries(LdeMap).map(([key, value]) => (
                              <option key={value} value={key}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-4 text-left">
                      <h2
                        style={{
                          color: 'rgb(177, 31, 36)',
                        }}
                      >
                        {getLang('Sales Data')}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {['Netherlands', 'Belgium', 'United Kingdom'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <label className="form-label text-left">
                          {getLang('BEES Relevant Flag')}
                        </label>
                        <Switch
                          checked={inputs.beesRelevantFlag}
                          name="beesRelevantFlag"
                          onChange={handleBeesChange}
                          onLabel="Yes"
                          offLabel="No"
                          // label="Promo Flag"
                          size="lg"
                          labelPosition="left"
                          color="yellow"
                          disabled={
                            isDisable ||
                            inputs.existingSoldToID == '' ||
                            (country == 'Belgium' && shipToType.includes('Indirect')) ||
                            country == 'Netherlands'
                          }
                        />
                      </div>
                    )}
                    <div className="col-12 col-lg-4">
                      <label className="form-label text-left">
                        {getLang('Does the customer need Promo?')}
                      </label>
                      <Switch
                        checked={inputs.promoNews}
                        name="promoNews"
                        onChange={handlePromoChange}
                        onLabel="Yes"
                        offLabel="No"
                        // label="Promo Flag"
                        size="lg"
                        labelPosition="left"
                        color="yellow"
                        disabled={isDisable || inputs.existingSoldToID == ''}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Segment')} {shipToType != 'Miscellaneous' ? ' *' : ''}{' '}
                        </label>
                        <select
                          id="select-segment"
                          className={`form-control ${errors.segment ? 'is-invalid' : ''}`}
                          onChange={setInputs}
                          name="segment"
                          value={inputs.segment}
                          disabled={
                            isDisableFields ||
                            isDisable ||
                            shipToType.startsWith('Wholesale') ||
                            (country == 'France' && shipToType.startsWith('Wholesale')) ||
                            customerReqType == '2nd Sold To'
                          }
                        >
                          <option value="">Select</option>
                          {segmentsRes.segments.map((val) =>
                            ['Italy'].includes(country) ? (
                              <option
                                key={val.segment}
                                value={val.segmentCode}
                                data-segment={val.segment}
                              >
                                {`${val.segmentCode} - ${val.segment}`}
                              </option>
                            ) : (
                              <option
                                key={val.segment}
                                value={val.segmentCode}
                                data-segment={val.segment}
                              >
                                {val.segment}
                              </option>
                            ),
                          )}
                        </select>
                        {errors.segment && (
                          <div className="invalid-feedback text-left">{errors.segment}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Sub Segment')} {shipToType != 'Miscellaneous' ? ' *' : ''}
                        </label>
                        <select
                          id="select-sub-segment"
                          className={`form-control ${errors.subSegment ? 'is-invalid' : ''}`}
                          value={inputs.subSegment}
                          name="subSegment"
                          onChange={setInputs}
                          disabled={
                            isDisableFields ||
                            !inputs.segment ||
                            isDisable ||
                            (country == 'France' && shipToType.startsWith('Wholesale')) ||
                            customerReqType == '2nd Sold To'
                          }
                        >
                          <option value="">Select</option>

                          {subSegmentsRes.subSegments.map((val) =>
                            ['Italy'].includes(country) ? (
                              <option
                                key={val.subSegment}
                                value={val.subSegmentCode}
                                data-subsegment={val.subSegment}
                              >
                                {`${val.subSegmentCode} - ${val.subSegment}`}
                              </option>
                            ) : (
                              <option
                                key={val.subSegment}
                                value={val.subSegmentCode}
                                data-subsegment={val.subSegment}
                              >
                                {val.subSegment}
                              </option>
                            ),
                          )}
                        </select>
                        {errors.subSegment && (
                          <div className="invalid-feedback text-left">{errors.subSegment}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('Excise duty type')}{' '}
                          {country === 'Belgium' && shipToType === 'On-Trade – Indirect'
                            ? ' *'
                            : ''}
                        </label>
                        {country === 'United Kingdom' && (
                          <input
                            type="text"
                            className="form-control"
                            name="exciseDutyType"
                            value={inputs.exciseDutyType}
                            disabled
                          />
                        )}
                        {country !== 'United Kingdom' && (
                          <select
                            id="select-excise"
                            className="form-control"
                            value={inputs.exciseDutyType}
                            name="exciseDutyType"
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'France' &&
                                ['On-Trade – Indirect', 'Off-Trade – Direct'].includes(
                                  shipToType,
                                )) ||
                              (country == 'Belgium' && shipToType == 'Off-Trade - Indirect') ||
                              (['Belgium', 'Netherlands'].includes(country) &&
                                customerReqType == 'Ship To') ||
                              customerReqType == '2nd Sold To'
                            }
                          >
                            {/* <option value="">Select</option> */}
                            {exciseOptions.map((opt) => (
                              <option
                                key={opt.key}
                                value={opt.key}
                                data-excise={`${opt.key}-${opt.val}`}
                              >
                                {`${opt.key}-${opt.val}`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    {shipToType.startsWith('Wholesaler') && (
                      <>
                        <CustomTextInput
                          title="Tax Warehouse Number"
                          name={'taxWarehouseNumber'}
                          errors={errors}
                          inputs={inputs}
                          placeholder=""
                          setInputs={setInputs}
                          onChange={setInputs}
                          maxLength="15"
                          getLang={getLang}
                          mandatory={inputs.exciseDutyType == '001' ? true : false}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                        <CustomTextInput
                          title="External ED Number"
                          name={'warehouseExternalEdNumber'}
                          errors={errors}
                          inputs={inputs}
                          placeholder=""
                          setInputs={setInputs}
                          onChange={setInputs}
                          maxLength="35"
                          getLang={getLang}
                          mandatory={inputs.exciseDutyType == '001' ? true : false}
                          disabled={
                            isDisableFields || isDisable || customerReqType == '2nd Sold To'
                          }
                        />
                      </>
                    )}
                    {((![
                      'Austria',
                      'Denmark',
                      'Finland',
                      'Norway',
                      'Poland',
                      'Sweden',
                      'Switzerland',
                    ].includes(country) &&
                      shipToType.includes('Indirect') &&
                      customerReqType != 'Ship To') ||
                      (['Belgium', 'Netherlands'].includes(country) &&
                        customerReqType == 'Ship To' &&
                        shipToType.includes('Direct')) ||
                      ['France'].includes(country)) &&
                      customerReqType != 'Ship To' && (
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('POC Supplier')}
                              {country === 'United Kingdom' ? ' (Ship-to)' : ''}
                              {(['Belgium', 'Germany'].includes(country) &&
                                shipToType.endsWith('Indirect')) ||
                              ['France'].includes(country)
                                ? ' *'
                                : ''}
                            </label>
                            {['United Kingdom'].includes(country) &&
                              shipToType.endsWith('Indirect') && (
                                <>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      errors.pocExtra ? 'is-invalid' : ''
                                    }`}
                                    name="pocSupplierShipTo"
                                    maxLength="8"
                                    value={inputs.pocSupplierShipTo}
                                    onChange={setInputs}
                                    disabled={
                                      isDisableFields ||
                                      isDisable ||
                                      customerReqType == '2nd Sold To'
                                    }
                                  />
                                  {errors.pocExtra && (
                                    <div className="invalid-feedback text-left">
                                      {errors.pocExtra}
                                    </div>
                                  )}
                                </>
                              )}
                            {((['Netherlands', 'Belgium', 'Germany'].includes(country) &&
                              shipToType.includes('Indirect') &&
                              customerReqType != 'Ship To') ||
                              (['France'].includes(country) && customerReqType != 'Ship To') ||
                              (['Belgium', 'Netherlands'].includes(country) &&
                                customerReqType == 'Ship To' &&
                                shipToType.includes('Direct'))) && (
                              <Select
                                className="text-left"
                                name="pocSupplierShipTo"
                                value={pocOptions.filter(
                                  (opt) => opt.pocNumber == inputs.pocSupplierShipTo,
                                )}
                                components={{ Input }}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: '#cfcfcf',
                                    primary50: '#cfcfcf',
                                    primary: 'orange',
                                  },
                                })}
                                isClearable
                                placeholder="Eg: 12345678"
                                isDisabled={
                                  isDisableFields || isDisable || customerReqType == '2nd Sold To'
                                }
                                getOptionLabel={({ pocDesc }) => pocDesc}
                                getOptionValue={({ pocNumber }) => pocNumber}
                                options={pocOptions}
                                onChange={(e, action) => {
                                  setInputs(e, action);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    {((['France', 'Belgium', 'Germany'].includes(country) &&
                      shipToType.includes('Indirect') &&
                      customerReqType != 'Ship To') ||
                      (['France'].includes(country) && customerReqType != 'Ship To') ||
                      (['Belgium', 'Netherlands'].includes(country) &&
                        shipToType.includes('Direct') &&
                        customerReqType == 'Ship To')) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('If POC Supplier not in list please specify here')}
                          </label>
                          {
                            <input
                              type="text"
                              className={`form-control ${errors.pocExtra ? 'is-invalid' : ''}`}
                              name="pocSupplierShipTo"
                              maxLength="8"
                              value={inputs.pocSupplierShipTo}
                              onChange={setInputs}
                              disabled={
                                isDisableFields || isDisable || customerReqType == '2nd Sold To'
                              }
                            />
                          }
                          {errors.pocExtra && (
                            <div className="invalid-feedback text-left">{errors.pocExtra}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {((country == 'France' &&
                      ['On-Trade – Direct', 'Wholesale– Direct'].includes(shipToType)) ||
                      country == 'United Kingdom' ||
                      (country == 'Netherlands' && shipToType.endsWith('Direct')) ||
                      shipToType.startsWith('Off-Trade') ||
                      country == 'Italy' ||
                      (country == 'Belgium' &&
                        [
                          'On-Trade – Direct',
                          'National On-Trade Direct',
                          'Off-Trade – Direct',

                          'Wholesaler - Sub Agent',
                          'Wholesaler - Main Agent',
                        ].includes(shipToType))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Delivering Plant')}
                            {country == 'Italy' ||
                            country == 'United Kingdom' ||
                            country == 'Netherlands' ||
                            (country == 'Belgium' &&
                              [
                                'On-Trade – Direct',
                                'National On-Trade Direct',
                                'Off-Trade – Direct',
                                'Off-Trade - Indirect',
                              ].includes(shipToType))
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            id="select-delivering-plant"
                            className={`form-control ${errors.deliveringPlant ? 'is-invalid' : ''}`}
                            name="deliveringPlant"
                            value={inputs.deliveringPlant}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'France' && shipToType == 'On-Trade – Direct') ||
                              customerReqType == '2nd Sold To'
                            }
                            onChange={setInputs}
                          >
                            <option value="">Select</option>

                            {country === 'Netherlands' && shipToType.startsWith('Off-Trade') && (
                              <>
                                <option key="NL02" value="NL02" data-plant="NL02 - DOMMELEN Prod">
                                  NL02 - DOMMELEN Prod
                                </option>
                                <option
                                  key="NL03"
                                  value="NL03"
                                  data-plant="NL03 - EERSEL DC WINCANTON"
                                >
                                  NL03 - EERSEL DC WINCANTON
                                </option>
                              </>
                            )}
                            {!(
                              (country == 'Belgium' && shipToType.includes('Wholesaler')) ||
                              (country === 'Netherlands' && shipToType.startsWith('Off-Trade'))
                            ) &&
                              deliveringPlantsRes.deliveringPlants.map((val) => (
                                <option
                                  key={val.plantCode}
                                  value={val.plantCode}
                                  data-plant={`${val.plantCode} - ${val.plantDesc}`}
                                >{`${val.plantCode} - ${val.plantDesc}`}</option>
                              ))}
                            {country == 'Belgium' &&
                              shipToType.includes('Wholesaler') &&
                              deliveringPlantsRes.deliveringPlants.map(
                                (val) =>
                                  val.plantCode != 'BE15' && (
                                    <option
                                      key={val.plantCode}
                                      value={val.plantCode}
                                      data-plant={`${val.plantCode} - ${val.plantDesc}`}
                                    >{`${val.plantCode} - ${val.plantDesc}`}</option>
                                  ),
                              )}
                          </select>
                          {errors.deliveringPlant && (
                            <div className="invalid-feedback text-left">
                              {errors.deliveringPlant}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {!['Belgium', 'France', 'Italy', 'Luxembourg'].includes(country) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Shipping Condition')}
                            {[
                              'Austria',
                              'Denmark',
                              'Finland',
                              'Norway',
                              'Poland',
                              'Sweden',
                              'Switzerland',
                            ].includes(country)
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            id="select-shipping-condition"
                            className="form-control"
                            name="shippingCondition"
                            value={inputs.shippingCondition}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              ['Denmark', 'Finland', 'Netherlands', 'Germany'].includes(country) ||
                              isDisable ||
                              customerReqType == '2nd Sold To'
                            }
                          >
                            {/* <option value="">Select</option> */}
                            {!['Austria', 'Poland', 'Sweden', 'Switzerland', 'Norway'].includes(
                              country,
                            ) &&
                              shippingConditionsRes.shippingConditions.map((val) => (
                                <option
                                  key={val.shippingConditionCode}
                                  value={val.shippingConditionCode}
                                  data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                >
                                  {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                </option>
                              ))}
                            {['Austria', 'Poland', 'Sweden', 'Switzerland', 'Norway'].includes(
                              country,
                            ) &&
                              shippingConditionsRes.shippingConditions
                                .filter((e) => ['T1', 'T2'].includes(e.shippingConditionCode))
                                .map((val) => (
                                  <option
                                    key={val.shippingConditionCode}
                                    value={val.shippingConditionCode}
                                    data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  >
                                    {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                                  </option>
                                ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {country === 'Sweden' && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivery Window')} {getLang('(From)')} *
                            </label>

                            <DatePicker
                              dateFormat={false}
                              value={inputs.delivWindow}
                              inputProps={{
                                name: 'delivWindow',
                                onKeyDown: (e) => e.preventDefault(),
                                readOnly: false,
                                placeholder: '--:--',
                                className: `form-control ${errors.delivWindow ? 'is-invalid' : ''}`,
                              }}
                              timeConstraints={{ minutes: { step: 30 } }}
                              onChange={(val) => {
                                setInputs({
                                  target: { name: 'delivWindow', value: val.format('HH:mm') },
                                });
                              }}
                              closeOnSelect="false"
                              timeFormat="HH:mm"
                            />
                            {errors?.delivWindow && (
                              <div style={{ color: '#cd201f', fontSize: '85%' }}>
                                {errors?.delivWindow}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Delivery Window')} {getLang('(To)')} *
                            </label>
                            {/* <label className="form-label text-left">{getLang('From')}</label> */}
                            <DatePicker
                              dateFormat={false}
                              value={dateRange}
                              inputProps={{
                                name: 'delivWindow',
                                placeholder: '--:--',
                                disabled: true,
                              }}
                              timeConstraints={{ minutes: { step: 30 } }}
                              closeOnSelect="false"
                              timeFormat="HH:mm"
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {country === 'United Kingdom' && (
                      <>
                        <div className="col-12 col-lg-4">
                          <div className="form-group">
                            <label className="form-label text-left">
                              {getLang('Hierarchy type - D Node')}
                            </label>
                            <select
                              id="select-hierarchy-type-d-node"
                              className="form-control"
                              name="hierarchyTypeDNode"
                              value={inputs.hierarchyTypeDNode}
                              onChange={setInputs}
                              disabled={
                                isDisableFields ||
                                isDisable ||
                                (country == 'United Kingdom' &&
                                  shipToType == 'On-Trade – Indirect') ||
                                customerReqType == '2nd Sold To'
                              }
                            >
                              <option value="">Select</option>
                              <option value="New">New</option>
                              <option value="Existing">Existing</option>
                            </select>
                          </div>
                        </div>
                        {inputs.hierarchyTypeDNode === 'Existing' && (
                          <div className="col-12 col-lg-4">
                            <div className="form-group">
                              <label className="form-label text-left">
                                {getLang('D-Node')}
                                {country == 'United Kingdom' ? ' (CPT)' : ''}
                              </label>
                              <input
                                type="text"
                                className={`form-control ${errors.dNodeCpt ? 'is-invalid' : ''}`}
                                name="dNodeCpt"
                                placeholder="Eg.: 105004"
                                value={inputs.dNodeCpt}
                                onChange={setInputs}
                                maxLength="6"
                                disabled={
                                  isDisableFields || isDisable || customerReqType == '2nd Sold To'
                                }
                              />
                              {errors.dNodeCpt && (
                                <div className="invalid-feedback text-left">{errors.dNodeCpt}</div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {(country === 'France' ||
                      country === 'Italy' ||
                      (country == 'Belgium' &&
                        (shipToType.startsWith('National') ||
                          shipToType.startsWith('On-Trade') ||
                          shipToType.startsWith('Off-Trade') ||
                          shipToType.endsWith('Indirect')))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Attribute Type 7')}
                            {country == 'Belgium' || country == 'Italy' || country == 'France'
                              ? ' *'
                              : ''}
                          </label>
                          <select
                            className={`form-control ${errors.subChannel ? 'is-invalid' : ''}`}
                            name="subChannel"
                            value={inputs.subChannel}
                            onChange={setInputs}
                            disabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'France' && shipToType.startsWith('Wholesale')) ||
                              customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            {country == 'Netherlands' &&
                              subChannels
                                .filter((e) => ['XXX', '001'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {country == 'France' &&
                              shipToType == 'Off-Trade – Direct' &&
                              subChannels
                                .filter((e) => ['001', '008'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {country == 'France' &&
                              shipToType != 'Off-Trade – Direct' &&
                              subChannels
                                .filter((e) => ['XXX', '001', '003', '008'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {(country == 'Belgium' || country == 'Italy') &&
                              shipToType.startsWith('On-Trade') &&
                              subChannels
                                .filter((e) => ['XXX', '001', '007', '016', '017'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {(country == 'Belgium' || country == 'Italy') &&
                              shipToType.startsWith('Off-Trade') &&
                              subChannels
                                .filter((e) => ['XXX', '002', '007', '010'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                            {country == 'Belgium' &&
                              shipToType.startsWith('National') &&
                              subChannels
                                .filter((e) => ['001', '003'].includes(e.key))
                                .map((val) => (
                                  <option key={val.key} value={val.value}>
                                    {val.value}
                                  </option>
                                ))}
                          </select>
                          {errors.subChannel && (
                            <div className="invalid-feedback text-left">{errors.subChannel}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {country == 'Germany' && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Customer Classification')}
                          </label>
                          <select
                            className="form-control"
                            name="customerClassification"
                            value={inputs.customerClassification}
                            onChange={setInputs}
                            disabled={
                              isDisableFields || isDisable || customerReqType == '2nd Sold To'
                            }
                          >
                            <option value="">Select</option>
                            <option value="A-Customer">A-Customer</option>
                            <option value="B-Customer">B-Customer</option>
                            <option value="C-Customer">C-Customer</option>
                            <option value="D-Customer">D-Customer</option>
                            <option value="E-Customer">E-Customer</option>
                            <option value="S-Customer">S-Customer</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {(country == 'Italy' ||
                      (country == 'Netherlands' && shipToType.startsWith('Off-Trade'))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">{getLang('Truck Type')} *</label>
                          <Select
                            className="text-left"
                            name="truckType"
                            value={truckOptions.filter((opt) => opt.truckCode == inputs.truckType)}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'Netherlands' && shipToType.startsWith('Off-Trade'))
                            }
                            getOptionLabel={({ truckDesc }) => getLang(truckDesc)}
                            getOptionValue={({ truckCode }) => truckCode}
                            options={truckOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.truckType && (
                            <div className="invalid-feedback-file text-left">
                              {getLang(errors.truckType)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {(country == 'Italy' ||
                      (country == 'Netherlands' && shipToType.startsWith('Off-Trade'))) && (
                      <div className="col-12 col-lg-4">
                        <div className="form-group">
                          <label className="form-label text-left">
                            {getLang('Trailer Type')} *
                          </label>
                          <Select
                            className="text-left"
                            name="trailerType"
                            value={trailerOptions.filter(
                              (opt) => opt.trailerCode == inputs.trailerType,
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#cfcfcf',
                                primary50: '#cfcfcf',
                                primary: 'orange',
                              },
                            })}
                            isClearable
                            placeholder="Select"
                            isDisabled={
                              isDisableFields ||
                              isDisable ||
                              (country == 'Netherlands' && shipToType.startsWith('Off-Trade'))
                            }
                            getOptionLabel={({ trailerDesc }) => getLang(trailerDesc)}
                            getOptionValue={({ trailerCode }) => trailerCode}
                            options={trailerOptions}
                            onChange={(e, action) => {
                              setInputs(e, action);
                            }}
                          />
                          {errors.trailerType && (
                            <div className="invalid-feedback-file text-left">
                              {getLang(errors.trailerType)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="card-footer">
          <div className="row">
            <div className="col-auto col-md-auto text-left">
              {!isDisable && (
                <Button variant="outline-warning" onClick={props.saveDraft}>
                  Save Draft
                </Button>
              )}
            </div>
            <div className="col col-md text-left justify-content">{props.draftMsg}</div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link="mainpage"
                data-back="true"
                className="btn btn-warning btn-block"
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                Back
              </Link>
            </div>
            <div className="col-auto col-md-auto text-right">
              <Link
                data-link={customerReqType === 'Ship To' ? 'review' : 'payer'}
                className={`btn btn-${
                  Object.keys(validateFields(inputs)).length == 0 || isDisable
                    ? 'danger'
                    : 'secondary'
                } btn-block`}
                style={{ textDecoration: 'none' }}
                onClick={handleSubmit}
              >
                {customerReqType === 'Ship To' ? 'Review' : 'Next'}
              </Link>
            </div>
          </div>
        </div>
      </form>
    )
  );
};

// inputs.name == '' ? null :

export default CreateCustShipTo;
