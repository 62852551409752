export function validateFields(values) {
  let errors = {};

  if (values.country === '') {
    errors.country = 'Country is required';
  } else if (values.customerReqType === '') {
    errors.customerReqType = 'Request Type is required';
  } else if (values.shipToType === '') {
    errors.shipToType = 'Ship-to Type is required';
  } else if (
    values.m1 === '' &&
    !values.shipToType.includes('Wholesaler') &&
    values.country !== 'France' &&
    !values.shipToType.includes('Off-Trade')
  ) {
    errors.m1 = 'M1 is required';
  }

  return errors;
}
